'use client';

import { useState } from 'react';

interface FeedbackModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function FeedbackModal({ isOpen, onClose }: FeedbackModalProps) {
    const [feedback, setFeedback] = useState('');
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');

    if (!isOpen) return null;

    const handleSubmit = async () => {
        if (!feedback.trim()) return;
        
        setIsSubmitting(true);
        setSubmitStatus('idle');

        try {
            const response = await fetch('/thomas-gia/api/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    feedback,
                    email: email.trim() || undefined,
                }),
            });

            if (!response.ok) throw new Error('Failed to send feedback');

            setSubmitStatus('success');
            setTimeout(() => {
                onClose();
                setFeedback('');
                setEmail('');
                setSubmitStatus('idle');
            }, 2000);
        } catch (error) {
            setSubmitStatus('error');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
                <div className="p-6 space-y-6">
                    <h2 className="text-2xl font-semibold text-slate-800">
                        Send Feedback
                    </h2>

                    <div className="space-y-4">
                        <div>
                            <label className="block text-slate-600 mb-1">
                                Your Feedback
                            </label>
                            <textarea
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                placeholder="Tell us what you think..."
                                rows={4}
                                className="w-full px-3 py-2 border border-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none"
                            />
                        </div>

                        <div>
                            <label className="block text-slate-600 mb-1">
                                Email (Optional)
                            </label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="your@email.com"
                                className="w-full px-3 py-2 border border-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            />
                            <p className="mt-1 text-sm text-slate-500">
                                Leave your email if you'd like us to follow up with you.
                            </p>
                        </div>

                        {submitStatus === 'error' && (
                            <p className="text-red-500">
                                Failed to send feedback. Please try again.
                            </p>
                        )}
                        {submitStatus === 'success' && (
                            <p className="text-green-500">
                                Thank you for your feedback!
                            </p>
                        )}
                    </div>
                </div>

                <div className="px-6 py-4 bg-slate-50 rounded-b-lg flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 border-2 border-slate-300 rounded-lg text-slate-600 hover:border-slate-400 transition-colors"
                        disabled={isSubmitting}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        disabled={!feedback.trim() || isSubmitting}
                        className={`px-4 py-2 bg-blue-500 text-white rounded-lg transition-colors ${
                            !feedback.trim() || isSubmitting
                                ? 'opacity-50 cursor-not-allowed'
                                : 'hover:bg-blue-600'
                        }`}
                    >
                        {isSubmitting ? 'Sending...' : 'Send Feedback'}
                    </button>
                </div>
            </div>
        </div>
    );
} 